<template>
  <div class="w-100">
    <pibot-page-heading>
      Assets overview
    </pibot-page-heading>

    <v-container>
      <v-data-table :headers="headers"
                    :items="assets"
                    :items-per-page="15"
                    :loading="!assetsLoaded"
                    sort-by="Name"
                    class="pibot-asset-overview-table">
        <!-- Action buttons -->
        <template v-slot:[`item.action`]="{ item }">
          <v-layout align-center
                    justify-center
                    fill-height>
            <v-btn
              fab
              dark
              small
              depressed
              color="primary darken-1"
              class="ma-1"
              :to="getMapLink(item)"
            >
              <v-icon dark>place</v-icon>
            </v-btn>
            <v-btn
              fab
              dark
              small
              depressed
              color="primary darken-1"
              class="ma-1"
              :to="`/${ns}/${item.Name}`"
            >
              <v-icon dark>info</v-icon>
            </v-btn>
          </v-layout>
        </template>
        <template v-slot:[`item.Total_risk`]="{ item }">
          <pibot-risk-chip :risk="item.Total_risk"
                          small
                          class="ml-2" />
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import PageHeading from '@/components/typography/headings/PageHeading'
import { mapState, mapGetters } from 'vuex'
import config from '../../../config'
import RiskChip from '@/components/chips/RiskChip'
import assetMetaMixin from '@/utils/mixins/meta/assetMeta.mixin'

export default {
  name: 'pibot-assets-overview',
  mixins: [assetMetaMixin],
  components: {
    'pibot-page-heading': PageHeading,
    'pibot-risk-chip': RiskChip
  },
  data () {
    return {
      ns: config.ns
    }
  },
  computed: {
    ...mapState({
      assetCollection: state => state.assets.assets
    }),
    ...mapGetters({
      assetsLoaded: 'assets/assetsLoaded'
    }),
    assets () {
      return this.assetCollection
    },
    headers () {
      // Dynamically setup header objects
      const headers = []
      const pushedKeys = []

      // If any assets loaded, set action column (otherwise this column will get shown while loading, which doesn't look nice)
      if (this.assets.length) headers.push({ text: '', value: 'action', sortable: false })

      // Set the keys of the properties we want to hide
      const propsToHide = ['category', 'D_temp', 'Coating', 'CP', 'W_coating', 'Tag_no_', 'Trenched', 'Diam_out', 'Nom_WT', 'Installed', 'D_pressure', 'Material', 'Field_coat', 'Service']

      this.assets.forEach(a => {
        for (const key in a) {
          if (pushedKeys.includes(key.toLowerCase()) || propsToHide.includes(key)) continue

          const meta = this.getAssetMetaByKey(key)
          if (meta) {
            headers.push({
              text: meta.title + (meta.unit ? ' [' + meta.unit + ']' : ''),
              align: key !== 'Total_risk' ? 'left' : 'center',
              sortable: true,
              value: key
            })

            pushedKeys.push(key.toLowerCase())
          }
        }
      })

      // Make sure the 'Highest Risk' column is always at the end
      headers.sort((a, b) => {
        if (a.text === 'Highest Risk') return 1
        if (b.text === 'Highest Risk') return -1
        return 0
      })

      // Set the divider property for all headers
      // All will be set to true, except the last one
      headers.forEach((h, i) => {
        h.divider = i < headers.length - 1
      })

      return headers
    }
  },
  methods: {
    getMapLink ({ layerId, Name }) {
      const prefix = '/mapviewer?zoomto='
      const layer = layerId || Name
      const encoded = encodeURI(layer.toLowerCase().split(' ').join('-'))
      return `${prefix}${encoded}`
    }
  }
}
</script>
